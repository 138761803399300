// @ts-ignore - no types for ar-feature-gating
import { splitClient } from '@nextroll/ar-feature-gating';

export const loadSplitIo = (id: string, onSplitClientReady: () => void) => {
    splitClient.load({
        environment: process.env.ENVIRONMENT,
        advertisable: {
            eid: id,
            organization: 'ORG_EID',
            business_unit: 'BUSINESS_UNIT',
        },
        user: {
            eid: id,
        },
        actualUser: {
            eid: id,
        },
    });

    splitClient.on(splitClient.READY_EVENT, onSplitClientReady);
};

export const getTreatment = (feature: string) =>
    splitClient.getTreatment(feature);
