import {
    ACTION_TYPES,
    SignUpActions,
} from '../../actions/sign-up/SignUpActions';
import { ImmutableSignUpState } from '../../contexts/sign-up/SignUpContext';

const SignUpReducer = (
    state: ImmutableSignUpState,
    action: SignUpActions
): ImmutableSignUpState => {
    switch (action.type) {
        case ACTION_TYPES.SIGN_UP_UPDATE_FIELD:
            // Note that this case uses mergeIn instead of setIn
            // This is because the field data is an object, and we want to merge the new data with the existing data.
            // This is necessary so we can have actions for specific lifecycle events, like onChange VS onBlur.
            return state.mergeIn(
                ['fields', action.payload.fieldName],
                action.payload.data
            );
        case ACTION_TYPES.SIGN_UP_UPDATE_EXTRA_FIELDS:
            return state.mergeIn(['extraFields'], action.payload.data);
        case ACTION_TYPES.SIGN_UP_SET_RECAPTCHA_TOKEN:
            return state
                .setIn(['reCaptcha', 'expiresAt'], action.payload.expiresAt)
                .setIn(['reCaptcha', 'token'], action.payload.token)
                .setIn(
                    ['reCaptcha', 'refreshesCount'],
                    (state.getIn(['reCaptcha', 'refreshesCount']) as number) + 1
                );
        case ACTION_TYPES.SIGN_UP_SET_GOOGLE_ID_TOKEN:
            return state.set('googleIdToken', action.payload.token);
        case ACTION_TYPES.SIGN_UP_SUBMIT_IN_PROGRESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'redirect'], null)
                .setIn(['submit', 'to'], null)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false);
        case ACTION_TYPES.SIGN_UP_SUBMIT_ERROR:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'redirect'], null)
                .setIn(['submit', 'to'], null)
                .setIn(['submit', 'error'], action.payload.error)
                .setIn(['submit', 'showError'], true);
        case ACTION_TYPES.SIGN_UP_SUBMIT_SUCCESS:
            return state
                .setIn(['submit', 'status'], action.payload.status)
                .setIn(['submit', 'redirect'], action.payload.redirect)
                .setIn(['submit', 'to'], action.payload.to)
                .setIn(['submit', 'error'], null)
                .setIn(['submit', 'showError'], false);
    }
};

export default SignUpReducer;
