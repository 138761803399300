import Footer from '../../components/Footer';
import Header from '../../components/Header';
import SignUpForm from '../../forms/sign-up/SignUpForm';

const SignUpPage = () => {
    return (
        <section className='entryhall-page-sign-up'>
            <Header />
            <SignUpForm />
            <Footer />
        </section>
    );
};

export default SignUpPage;
