import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import { Link } from 'react-router-dom';

import { AdrollApiRequestError } from '@nextroll/ar-base-api';
import i18n from '@nextroll/ar-i18n';

export enum ErrorId {
    required_field = 'required_field',
    invalid_email = 'invalid_email',
    password_not_valid = 'password_not_valid',
    confirmation_not_match = 'confirmation_not_match',
    password_reused = 'password_reused',
    password_not_expired = 'password_not_expired',
    invalid_password = 'invalid_password',
    invalid_phone = 'invalid_phone',
    request_failed = 'request_failed',
    user_or_password_error = 'user_or_password_error',
    user_password_invalidated = 'user_password_invalidated',
    user_locked = 'user_locked',
    user_not_approved = 'user_not_approved',
    invalid_tfa_token = 'invalid_tfa_token',
    email_tfa_not_enabled = 'email_tfa_not_enabled',
    email_rate_limited = 'email_rate_limited',
    sms_failed = 'sms_failed',
    sms_rate_limited = 'sms_rate_limited',
    sms_region_not_enabled = 'sms_region_not_enabled',
    sms_tfa_already_enabled = 'sms_tfa_already_enabled',
    email_not_verified = 'email_not_verified',
    sso_rejection = 'sso_rejection',
    saml_error = 'saml_error',
    user_exists = 'user_exists',
    invalid_email_provider = 'invalid_email_provider',
    google_sign_in_error = 'google_sign_in_error',
    google_sign_up_error = 'google_sign_up_error',
    google_not_authoritative = 'google_not_authoritative',
    google_user_not_found = 'google_user_not_found',
    rollworks_country_not_allowed = 'rollworks_country_not_allowed',
}

export interface IApiError {
    errorId: keyof typeof ErrorId | undefined;
    errorMessage: string;
    statusCode: number | undefined;
}

export const ERROR_MESSAGES = {
    [ErrorId.required_field]: i18n.noop.gettext('This field is required.'),
    [ErrorId.invalid_email]: i18n.noop.gettext(
        'Please enter a valid email address.'
    ),
    [ErrorId.password_not_valid]: i18n.noop.gettext(
        'Please create a password that meets the requirements.'
    ),
    [ErrorId.confirmation_not_match]: i18n.noop.gettext(
        "New password and confirmation don't match."
    ),
    [ErrorId.password_reused]: i18n.noop.gettext(
        "Looks like you've already used this one. Your new password must be different from your previous passwords."
    ),
    [ErrorId.password_not_expired]: i18n.noop.gettext(
        "Looks like you've already updated your password recently."
    ),
    [ErrorId.invalid_password]: i18n.noop.gettext(
        'Your current password is incorrect.'
    ),
    [ErrorId.invalid_phone]: i18n.noop.gettext(
        'Please enter a valid phone number. Specify your full phone number, including the country code starting with a plus symbol (+).'
    ),
    [ErrorId.request_failed]: i18n.noop.gettext(
        'Something went wrong with this request, please try again.'
    ),
    [ErrorId.user_or_password_error]: i18n.noop.gettext(
        'Incorrect email address or password.'
    ),
    [ErrorId.user_password_invalidated]: i18n.gettext(
        'Your password was deactivated to keep your account safe. Please <LINK>create a new password to log in<LINK>.',
        {
            LINK: <Link to='/lostpassword' />,
        }
    ),
    [ErrorId.user_locked]: i18n.noop.gettext('User temporary locked.'),
    [ErrorId.user_not_approved]: i18n.noop.gettext('User status not approved.'),
    [ErrorId.invalid_tfa_token]: i18n.noop.gettext(
        'Invalid code. Please try again.'
    ),
    [ErrorId.email_tfa_not_enabled]: i18n.noop.gettext(
        'This Two - Step authentication method is not enabled yet, please configure it from your setting pages.'
    ),
    [ErrorId.email_rate_limited]: i18n.noop.gettext(
        'Email rate limit exceeded.'
    ),
    [ErrorId.sms_failed]: i18n.noop.gettext(
        'Failed to send SMS. Please try again.'
    ),
    [ErrorId.sms_rate_limited]: i18n.noop.gettext('SMS rate limit exceeded.'),
    [ErrorId.sms_region_not_enabled]: i18n.gettext(
        'We do not currently support sending SMS to your region. Please use an <LINK>authentication app for two-factor authentication</LINK>.',
        {
            LINK: (
                <Link
                    to='/setup-mobile-app-tfa'
                    className='setup-tfa-app-link'
                />
            ),
        }
    ),
    [ErrorId.sms_tfa_already_enabled]: i18n.noop.gettext(
        'This Two - Step authentication method is already enabled, please configure it from your setting pages.'
    ),
    [ErrorId.email_not_verified]: i18n.noop.gettext(
        'Email must be verified before Email TFA can be enabled.'
    ),
    [ErrorId.sso_rejection]: i18n.gettext(
        'Your organization has Single Sign-On enabled. Please use the <LINK>SSO option to sign in.</LINK>',
        {
            LINK: <Link to='/saml' className='sso-link' />,
        }
    ),
    [ErrorId.saml_error]: i18n.noop.gettext(
        "It looks like you're not set up for SSO sign-in yet. Please use your email and password to log in, and contact us if you'd like to enable SSO."
    ),
    [ErrorId.user_exists]: i18n.noop.gettext(
        'User already exists with this email. Please choose another.'
    ),
    [ErrorId.invalid_email_provider]: i18n.noop.gettext(
        'This email address is not allowed. Please provide a valid, non-disposable email address to sign up.'
    ),
    [ErrorId.google_sign_in_error]: i18n.noop.gettext(
        'There was an error validating your Google sign-in. Please try again.'
    ),
    [ErrorId.google_sign_up_error]: i18n.noop.gettext(
        'There was an error validating your Google sign-up. Please try again.'
    ),
    [ErrorId.google_not_authoritative]: i18n.noop.gettext(
        'We only support Google Accounts with Gmail addresses or Google Workspace accounts.'
    ),
    [ErrorId.google_user_not_found]: i18n.gettext(
        "We could't find an account linked to this Google sign-in. To continue, <LINK>sign up</LINK> and create your account.",
        {
            LINK: <Link to='/signup' />,
        }
    ),
    [ErrorId.rollworks_country_not_allowed]: i18n.gettext(
        'RollWorks is only available in North American countries. If you want to choose another country, please <LINK>switch to AdRoll signup</LINK>.',
        {
            // We need to use `a` instead of `Link` because we want to force page reload
            LINK: (
                <a
                    href='/profile/signup?product=adroll'
                    className='signup-link'
                />
            ),
        }
    ),
};

export const reportApiError = (error: AdrollApiRequestError) => {
    const apiErr = error instanceof AdrollApiRequestError;

    const errMeta = {
        apiErrorInfo: apiErr ? error.getApiErrorInfo() : 'N/A',
        apiErrorMessage: apiErr ? error.getApiErrorMessage() : 'N/A',
        apiErrorCode: apiErr ? error.getApiErrorCode() : 'N/A',
        apiErrorField: apiErr ? error.getApiErrorField() : 'N/A',
    };

    reportError(error, errMeta);
};

export const reportError = (error: Error, errMeta: {}) => {
    Sentry.addBreadcrumb({
        message: 'error data',
        data: errMeta,
    });
    Sentry.captureException(error);

    datadogRum.addError(error, errMeta);
};
