import { useContext } from 'react';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-password-input
import PasswordInput from '@nextroll/ar-password-input';
// @ts-ignore - no types for ar-style-base
import { InputGroup } from '@nextroll/ar-style-base';

import {
    setFieldFactory,
    setShowFieldErrorFactory,
} from '../../../actions/sign-up/SignUpActions';
// @ts-ignore - no types for an image.
import GoogleIcon from '../../../assets/google.svg';
import SignUpContext from '../../../contexts/sign-up/SignUpContext';

const srcGoogleIcon = `${process.env.STATIC_PATH}${GoogleIcon}`;

const Password = () => {
    const { state, dispatch } = useContext(SignUpContext);

    const field = state.get('fields').password;
    const isGSignUp = state.get('googleIdToken') !== '';
    const setField = setFieldFactory(dispatch, 'password');
    const onChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        isValid: boolean,
        errors: string[]
    ) => {
        const value = e.target.value;
        setField(value, isValid, errors);
    };
    const setShowFieldError = setShowFieldErrorFactory(dispatch, 'password');

    return isGSignUp ? (
        <div className='entryhall-input-field-wrapper has-feedback has-feedback-right'>
            <InputGroup
                i18n={i18n}
                classNames='entryhall-input-password-placeholder'
                inputProps={{
                    disabled: true,
                    placeholder: i18n.gettext('Sign In With Google'),
                }}
            />
            <img
                className='form-control-feedback google-icon'
                src={srcGoogleIcon}
            />
        </div>
    ) : (
        <PasswordInput
            i18n={i18n}
            classNames='entryhall-input-password'
            enableValidation={true}
            inputProps={{
                autoComplete: 'new-password',
                name: 'password',
                placeholder: i18n.gettext('Password'),
                value: field.value,
                disabled: isGSignUp,
                onChange,
                onFocus: () => setShowFieldError(false),
                onBlur: () => setShowFieldError(true),
            }}
            errors={field.error}
            showErrors={field.showError}
        />
    );
};

export default Password;
