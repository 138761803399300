import i18n from '@nextroll/ar-i18n';

import { RememberMyDeviceStatuses } from '../../constants';

const MESSAGES = {
    [RememberMyDeviceStatuses.expired]: i18n.noop.gettext(
        'For your security, {{buName}} requires verification every 30 days. Your last check-in was a month ago. Please enter your verification code to continue.'
    ),
    [RememberMyDeviceStatuses.invalid]: i18n.noop.gettext(
        'Your saved device is no longer recognized. For security purposes, please verify your identity with your code.'
    ),
    [RememberMyDeviceStatuses.unknown]: i18n.noop.gettext(
        '{{buName}} cares about your security. We have set up your account with two-step verification. Please enter your verification code.'
    ),
};

interface IRememberMyDeviceStatusProps {
    buName: string;
    status: keyof typeof RememberMyDeviceStatuses;
}

const RememberMyDeviceStatus = ({
    buName,
    status,
}: IRememberMyDeviceStatusProps) => {
    const s = RememberMyDeviceStatuses[status];
    const errorMessage = i18n.gettext(MESSAGES[s], {
        buName,
    });

    return <p>{errorMessage}</p>;
};

export default RememberMyDeviceStatus;
