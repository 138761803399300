import { useEffect } from 'react';
import {
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes,
} from 'react-router-dom';

import ErrorPage from '../pages/error/ErrorPage';
import PasswordExpiredPage from '../pages/password-expired/PasswordExpiredPage';
import PasswordResetInitPage from '../pages/password-reset/PasswordResetInitPage';
import PasswordResetSavePage from '../pages/password-reset/PasswordResetSavePage';
import LockedAccountPage from '../pages/sign-in/LockedAccountPage';
import SamlSignInPage from '../pages/sign-in/SamlSignInPage';
import SignInPage from '../pages/sign-in/SignInPage';
import TFAPage from '../pages/sign-in/TFAPage';
import SignUpExperimentLandingPage from '../pages/sign-up/SignUpExperimentLandingPage';
import SignUpPage from '../pages/sign-up/SignUpPage';
import SignUpPageExperiment1 from '../pages/sign-up/SignUpPageExperiment1';
import SignUpPageExperiment2 from '../pages/sign-up/SignUpPageExperiment2';
import AppTFASetupPage from '../pages/tfa-setup/AppTFASetupPage';
import EmailTFASetupPage from '../pages/tfa-setup/EmailTFASetupPage';
import SmsTFASetupPage from '../pages/tfa-setup/SmsTFASetupPage';
import SmsTFASetupValidationPage from '../pages/tfa-setup/SmsTFASetupValidationPage';
import TFAMethodsPage from '../pages/tfa-setup/TFAMethodsPage';
import { fetchCSRFWithRetry } from '../utils/csrf';
import GoogleIdentityProvider from './GoogleIdentityProvider';
import ReCaptchaProvider from './ReCaptchaProvider';
import StatusWrapper from './StatusWrapper';
import ThemeWrapper from './ThemeWrapper';

const BASE_PATH = process.env.BASE_PATH;

const Root = () => {
    useEffect(() => {
        // cannot remove the brackets because
        // useEffect is not happy receiving a Promise as return
        fetchCSRFWithRetry();
    });

    return (
        <ThemeWrapper>
            <div className='ar-page-content-full-width entryhall-page'>
                <Router basename={BASE_PATH}>
                    <Routes>
                        <Route
                            path='/lockedaccount'
                            element={<LockedAccountPage />}
                        ></Route>
                        <Route
                            path='/lostpassword'
                            element={<PasswordResetInitPage />}
                        />
                        <Route
                            path='/resetpassword'
                            element={<PasswordResetSavePage />}
                        />
                        <Route
                            path='/setup-mobile-app-tfa'
                            element={<AppTFASetupPage />}
                        />
                        <Route
                            path='/setup-email-tfa'
                            element={<EmailTFASetupPage />}
                        />
                        <Route
                            path='/setup-sms-tfa'
                            element={<SmsTFASetupPage />}
                        />
                        <Route
                            path='/validate-sms-tfa'
                            element={<SmsTFASetupValidationPage />}
                        />
                        <Route
                            path='/tfa_configure'
                            element={<TFAMethodsPage />}
                        />
                        <Route
                            path='/saml'
                            element={
                                <StatusWrapper>
                                    <SamlSignInPage />
                                </StatusWrapper>
                            }
                        />
                        <Route
                            path='/signin'
                            element={
                                <StatusWrapper>
                                    <ReCaptchaProvider>
                                        <GoogleIdentityProvider>
                                            <SignInPage />
                                        </GoogleIdentityProvider>
                                    </ReCaptchaProvider>
                                </StatusWrapper>
                            }
                        />
                        <Route
                            path='/verifytfa'
                            element={
                                <StatusWrapper>
                                    <TFAPage />
                                </StatusWrapper>
                            }
                        />
                        <Route
                            path='/passwordexpired'
                            element={<PasswordExpiredPage />}
                        />
                        <Route
                            path='/signup'
                            element={
                                <StatusWrapper>
                                    <SignUpExperimentLandingPage />
                                </StatusWrapper>
                            }
                        />
                        <Route
                            path='/signup-1'
                            element={
                                <StatusWrapper>
                                    <ReCaptchaProvider>
                                        <GoogleIdentityProvider>
                                            <SignUpPageExperiment1 />
                                        </GoogleIdentityProvider>
                                    </ReCaptchaProvider>
                                </StatusWrapper>
                            }
                        />
                        <Route
                            path='/signup-2'
                            element={
                                <StatusWrapper>
                                    <ReCaptchaProvider>
                                        <GoogleIdentityProvider>
                                            <SignUpPageExperiment2 />
                                        </GoogleIdentityProvider>
                                    </ReCaptchaProvider>
                                </StatusWrapper>
                            }
                        />
                        <Route
                            path='/signup-3'
                            element={
                                <StatusWrapper>
                                    <ReCaptchaProvider>
                                        <GoogleIdentityProvider>
                                            <SignUpPage />
                                        </GoogleIdentityProvider>
                                    </ReCaptchaProvider>
                                </StatusWrapper>
                            }
                        />
                        <Route
                            path='/signup-4'
                            element={
                                <StatusWrapper>
                                    <ReCaptchaProvider>
                                        <GoogleIdentityProvider>
                                            <SignUpPage />
                                        </GoogleIdentityProvider>
                                    </ReCaptchaProvider>
                                </StatusWrapper>
                            }
                        />
                        <Route path='/error/:code' element={<ErrorPage />} />
                        {/* If a path is not found in the list, the user will be redirected to sign in page */}
                        <Route
                            path='/*'
                            element={<Navigate to='/signin' replace />}
                        />
                    </Routes>
                </Router>
            </div>
        </ThemeWrapper>
    );
};

export default Root;
