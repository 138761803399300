import i18n from '@nextroll/ar-i18n';

const ToS = () => {
    return (
        <div className='entryhall-form-signup-tos'>
            <span className='help-text'>
                {i18n.gettext(
                    "By filling out this form and clicking Sign Up, you confirm that you have read and agree to <TOSLink>NextRoll's Terms of Service </TOSLink> and have read and acknowledge <PNLink>NextRoll's Privacy Notice</PNLink>.",
                    {
                        TOSLink: <a href='https://www.nextroll.com/terms' />,
                        PNLink: <a href='https://www.nextroll.com/privacy' />,
                    }
                )}
            </span>
        </div>
    );
};

export default ToS;
