import { AdrollApi, Environment, RequestMethod } from '@nextroll/ar-base-api';

// Internal API call to AdRoll API with common fetch options.
// The redirect option is set to 'error' to make fetch reject the promise with a network error when a redirect status is returned.
class InternalAdrollApi extends AdrollApi {
    getCommonFetchOptions(method: RequestMethod): RequestInit {
        var fetchOptions = super.getCommonFetchOptions(method);
        fetchOptions.redirect = 'error'; // This is not an AdrollApiRequestError.
        return fetchOptions;
    }
}

// Init AdRoll API, accessing `data.results` for any JSON response.
export const adrollApi = new InternalAdrollApi({
    environment: process.env.ENVIRONMENT as Environment,
});
