import { useContext } from 'react';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import { Logo } from '@nextroll/ar-style-base';

// assets
// @ts-ignore - no types for an image.
import LogoAlgaeCal from '../../assets/signup-experiment-1/logo-algaecal_light.png';
// @ts-ignore - no types for an image.
import LogoBurkeDecor from '../../assets/signup-experiment-1/logo-burke_decor_light.png';
// @ts-ignore - no types for an image.
import LogoCoinRule from '../../assets/signup-experiment-1/logo-coinrule_light.png';
// @ts-ignore - no types for an image.
import LogoTMGM from '../../assets/signup-experiment-1/logo-tmgm_light.png';
// @ts-ignore - no types for an image.
import Medal1 from '../../assets/signup-experiment-1/medal_1.svg';
// @ts-ignore - no types for an image.
import Medal2 from '../../assets/signup-experiment-1/medal_2.svg';
// @ts-ignore - no types for an image.
import Medal3 from '../../assets/signup-experiment-1/medal_3.svg';
// @ts-ignore - no types for an image.
import Medal4 from '../../assets/signup-experiment-1/medal_4.svg';
// @ts-ignore - no types for an image.
import Medal5 from '../../assets/signup-experiment-1/medal_5.svg';
// @ts-ignore - no types for an image.
import PpPrize from '../../assets/signup-experiment-1/pp_prize.png';
import Footer from '../../components/Footer';
import { MARKETING_WEBSITE_URLS } from '../../constants';
import ThemeWrapperContext from '../../contexts/ThemeWrapperContext';
import SignUpForm from '../../forms/sign-up/SignUpForm';

const srcPpPrize = `${process.env.STATIC_PATH}${PpPrize}`;
const srcMedal1 = `${process.env.STATIC_PATH}${Medal1}`;
const srcMedal2 = `${process.env.STATIC_PATH}${Medal2}`;
const srcMedal3 = `${process.env.STATIC_PATH}${Medal3}`;
const srcMedal4 = `${process.env.STATIC_PATH}${Medal4}`;
const srcMedal5 = `${process.env.STATIC_PATH}${Medal5}`;
const srcLogoAlgaeCal = `${process.env.STATIC_PATH}${LogoAlgaeCal}`;
const srcLogoBurkeDecor = `${process.env.STATIC_PATH}${LogoBurkeDecor}`;
const srcLogoCoinRule = `${process.env.STATIC_PATH}${LogoCoinRule}`;
const srcLogoTMGM = `${process.env.STATIC_PATH}${LogoTMGM}`;

const SignUpPageExperiment1 = () => {
    const { businessUnit } = useContext(ThemeWrapperContext);

    const getTitle = () => {
        return (
            <h1 className='signup-exp1-content-title'>
                {i18n.gettext(
                    'Drive growth you can count on with ads your customers actually want to see.'
                )}
            </h1>
        );
    };
    return (
        <section className='entryhall-page-signup-exp1'>
            <link
                href='https://fonts.googleapis.com/css2?family=Work+Sans:wght@700&display=swap'
                rel='stylesheet'
            ></link>
            <div className='signup-exp1-header'>
                <a href={MARKETING_WEBSITE_URLS[businessUnit]}>
                    <Logo
                        i18n={i18n}
                        businessUnit={businessUnit}
                        height='30px'
                    />
                </a>
            </div>
            <div className='signup-exp1-content'>
                <div className='signup-exp1-content-left'>
                    {getTitle()}
                    <div className='signup-exp1-content-description'>
                        <p>
                            {i18n.gettext(
                                'AdRoll empowers businesses with AI-driven tools to deliver personalized, high-performing ad campaigns that respect user privacy and drive measurable results.'
                            )}
                        </p>
                        <ul>
                            <li>
                                {i18n.gettext(
                                    'Boost brand awareness, engage your audience, and grow your revenue with AdRoll’s all-in-one advertising platform.'
                                )}
                            </li>
                            <li>
                                {i18n.gettext(
                                    'Leverage cutting-edge AI and data-driven insights to optimize campaigns across every channel, ensuring results you can rely on.'
                                )}
                            </li>
                            <li>
                                {i18n.gettext(
                                    'Join over 140,000 brands who trust AdRoll to simplify their advertising while delivering exceptional outcomes.'
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className='signup-exp1-content-medals'>
                        <div>
                            <ul>
                                <li>
                                    <img src={srcPpPrize} alt='' />
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <img src={srcMedal1} alt='' />
                                </li>
                                <li>
                                    <img src={srcMedal2} alt='' />
                                </li>
                                <li>
                                    <img src={srcMedal3} alt='' />
                                </li>
                                <li>
                                    <img src={srcMedal4} alt='' />
                                </li>
                                <li>
                                    <img src={srcMedal5} alt='' />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='signup-exp1-content-right'>
                    {getTitle()}
                    <SignUpForm />
                </div>
            </div>
            <div className='signup-exp1-footer'>
                <div className='signup-exp1-footer-logos'>
                    <ul>
                        <li>
                            <img src={srcLogoAlgaeCal} alt='AlgaeCal' />
                        </li>
                        <li>
                            <img src={srcLogoBurkeDecor} alt='Burke Decor' />
                        </li>
                        <li>
                            <img src={srcLogoCoinRule} alt='Coin Rule' />
                        </li>
                        <li>
                            <img src={srcLogoTMGM} alt='TMGM' />
                        </li>
                    </ul>
                </div>
                <div className='signup-exp1-footer-bottom'>
                    <Footer />
                </div>
            </div>
        </section>
    );
};

export default SignUpPageExperiment1;
