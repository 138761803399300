export const getFromLocalStorage = (key: string): string | null => {
    try {
        return localStorage.getItem(key);
    } catch (err) {
        console.error('Error while reading from localStorage', err);
        return null;
    }
};

export const setToLocalStorage = (key: string, value: any) => {
    try {
        localStorage.setItem(key, value);
    } catch (err) {
        console.error('Error while setting to localStorage', err);
    }
};
