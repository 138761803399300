import Cookies from 'js-cookie';

import { reportError } from '../../errors';
import { ExtraFields, Fields } from './fields';

const MUNCHKIN_ID = '964-WFU-818';

const INTEGRATIONS = {
    GOOGLE_ANALYTICS: {
        getSrc: () => 'https://www.google-analytics.com/analytics.js',
        getOnLoad: () => () => {
            window.ga.l = +new Date();
            window.ga('create', 'UA-3221223-1', 'auto', {});
        },
    },
    GOOGLE_TAG_MANAGER: {
        getSrc: () => 'https://www.googletagmanager.com/gtm.js?id=GTM-W4PPZ6',
        getOnLoad: () => () => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
            });
        },
    },
    MARKETO: {
        getSrc: () => 'https://munchkin.marketo.net/munchkin.js',
        getOnLoad: () => () => {
            if (window.Munchkin) {
                window.Munchkin.init(MUNCHKIN_ID);
                if (!Cookies.get('_mkto_trk')) {
                    window.Munchkin.createTrackingCookie();
                }
            }
        },
    },
    MARKETO_FORM: {
        getSrc: () => '//pages.adroll.com/js/forms2/js/forms2.min.js',
        getOnLoad: () => () => {
            if (window.MktoForms2) {
                window.MktoForms2.loadForm(
                    '//pages.adroll.com',
                    '964-WFU-818',
                    1504,
                    (form: unknown) => {
                        window.MARKETO_FORMS_2 = form;
                    }
                );
            }
        },
    },
};

export const getIntegrationScript = (name: string): HTMLElement | null =>
    document.getElementById(`marketing-integration-${name}`);

export const loadMarketingIntegrations = () => {
    Object.entries(INTEGRATIONS).forEach(([name, integration]) => {
        if (getIntegrationScript(name)) {
            return;
        }
        const script = document.createElement('script');
        script.src = integration.getSrc();
        script.onload = integration.getOnLoad();
        script.id = `marketing-integration-${name}`;
        document.body.appendChild(script);
    });
};

export const removeMarketingIntegrations = () => {
    Object.keys(INTEGRATIONS).forEach((name) => {
        const script = getIntegrationScript(name);
        if (script) {
            document.body.removeChild(script);
        }
    });
};

/**
 * Sends a Marketo form submission, containing the GA client ID.
 * https://experienceleague.adobe.com/en/docs/marketo-developer/marketo/javascriptapi/forms-api-reference
 */
export const sendMarketoToGAEvent = async (
    fields: Fields,
    extraFields: ExtraFields
) =>
    new Promise<void>((resolve) => {
        try {
            if (!window.MARKETO_FORMS_2 || !window.ga) {
                resolve();
                return;
            }

            const form = window.MARKETO_FORMS_2;
            const formId = form.getId();
            const tracker = window.ga.getAll()[0];
            const gacid = tracker ? tracker.get('clientId') : 'n/a';

            const onSuccessFn = () => {
                if (window.dataLayer) {
                    // This is instantiated by INTEGRATIONS.GOOGLE_TAG_MANAGER
                    // It may not exist if GTM fails to load (or if Cypress blocks it).
                    window.dataLayer.push({
                        event: 'mktoLead',
                        mktoFormId: formId,
                    });
                }

                resolve();
                return false;
            };

            const formVals = {
                ...prepareMarketoFormValues(fields, extraFields),
                gacid,
            };

            form.setValues(formVals);
            form.addHiddenFields(formVals);
            form.onSuccess(onSuccessFn);
            form.submit();

            // TODO this timeout is a temporary solution to avoid
            // blocking the user if the Marketo form submission fails.
            // It will always fire, even if the form submission is successful.
            // But we redirect away from the page after the form submission so it's not a big deal ATM
            // FIXME if we remain on the page after the form submission, we should handle this better.
            setTimeout(() => {
                // We always resolve the promise after some time, but we log an error
                reportError(new Error('Marketo form submission timeout'), {
                    ga: JSON.stringify(window.ga),
                    tracker: JSON.stringify(tracker),
                    gacid,
                    formId,
                    marketoWindow: JSON.stringify(window.MARKETO_FORMS_2),
                });
                resolve();
            }, 2000);
        } catch (e) {
            console.error('Error sending Marketo to GA event', e);
            resolve(); // We always resolve the promise
        }
    });

export const prepareMarketoFormValues = (
    fields: Fields,
    extraFields: ExtraFields
): { [k: string]: any } => ({
    FirstName: fields.firstName.value,
    LastName: fields.lastName.value,
    Email: fields.email.value,
    Website: fields.url.value,
    // Sign-up form does not have a company field,
    // but instead of passing a 'n/a' value, it
    // was agreed to pass the company URL
    Company: fields.url.value,
    // Marketo form requires a country value in a
    // specific format, but this value is not
    // used for the Marketo/GA integration,
    // so setting a default here
    Country: 'United States of America',
    // Sign-up form does not have a these fields
    // but the Marketo form requirs these values
    // so explicitly setting it here
    Phone: '',
    Title: 'n/a',
    Express_Consent__c: false,
    Monthly_Advertising_Budget__c: 'None',
    Additional_Comments_Questions__c: 'n/a',
    // New fields, obtained from EXTRA_FIELDS_FROM_COOKIES.
    UTM_Source__c: extraFields.utm_source,
    UTM_Medium__c: extraFields.utm_medium,
    UTM_Campaign__c: extraFields.utm_campaign,
    UTM_Term__c: extraFields.utm_term,
    UTM_Content__c: extraFields.utm_content,
});
