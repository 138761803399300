/**
 * Converts a camelCase string to snake_case.
 * @param str - The camelCase string to convert.
 * @returns The snake_case version of the input string.
 */
export const camelToSnake = (str: string): string => {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

/**
 * Checks if a given URL is an absolute URL.
 *
 * This function is inspired by the backend logic (h.is_absolute_url, https://github.com/SemanticSugar/adroll/blob/f8e071ecdddd0f63813e71f6ca3bf8f0b3b21370/adroll/dotcom/lib/helpers/__init__.py#L376
 *
 * @param url - The URL to check.
 * @returns True if the URL is absolute, false otherwise.
 */
export const isAbsoluteUrl = (url: string): boolean => {
    try {
        new URL(url);
        return true;
    } catch {}

    return url.startsWith('//');
};
