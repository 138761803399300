import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Footer from '../../components/Footer';
import FormSpinner from '../../components/FormSpinner';
import Header from '../../components/Header';
import { BU_ADROLL } from '../../constants';
import ThemeWrapperContext from '../../contexts/ThemeWrapperContext';
import { getTreatment, loadSplitIo } from '../../utils/ab';
import {
    getFromLocalStorage,
    setToLocalStorage,
} from '../../utils/localstorage';

const SPLITIO_SIGNUP_EXP = 'nwa-signup-experiments-GIS';
const LS_KEY = `ab-anon-user-v2`;
const CONTROL_VARIANT = 'signup-3';

const SignUpExperimentLandingPage = () => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const { businessUnit } = useContext(ThemeWrapperContext);

    useEffect(() => {
        if (window.Cypress) {
            console.log('Cypress detected, skipping AB test');
            navigate(`../${CONTROL_VARIANT}${search}`);
            return;
        }
        if (businessUnit !== BU_ADROLL) {
            // Google is always enabled in the RW signup form.
            navigate(`../signup-4${search}`);
            return;
        }
        let userId = getFromLocalStorage(LS_KEY);
        if (!userId) {
            userId = `anon-user-b${Math.floor(Math.random() * 100)}`;
            setToLocalStorage(LS_KEY, userId);
        }

        loadSplitIo(userId, () => {
            let t = getTreatment(SPLITIO_SIGNUP_EXP);
            // If control or off, fallback to control variant.
            if (t === 'control' || t === 'off') {
                t = CONTROL_VARIANT;
            }

            navigate(`../${t}${search}`);
        });
    }, []);

    return (
        <section className='entryhall-page-sign-up'>
            <Header />
            <FormSpinner />
            <Footer />
        </section>
    );
};

export default SignUpExperimentLandingPage;
