import { useContext } from 'react';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import { Logo } from '@nextroll/ar-style-base';

// @ts-ignore - no types for an image.
import LogoAlgaeCal from '../../assets/signup-experiment-2/logo-algaecal.png';
// @ts-ignore - no types for an image.
import LogoBurkeDecor from '../../assets/signup-experiment-2/logo-burke-decor.png';
// @ts-ignore - no types for an image.
import LogoCoinRule from '../../assets/signup-experiment-2/logo-coinrule.png';
// @ts-ignore - no types for an image.
import LogoTMGM from '../../assets/signup-experiment-2/logo-tmgm.png';
// @ts-ignore - no types for an image.
import MetricsUI from '../../assets/signup-experiment-2/metrics-ui.png';
import Footer from '../../components/Footer';
import { MARKETING_WEBSITE_URLS } from '../../constants';
import ThemeWrapperContext from '../../contexts/ThemeWrapperContext';
import SignUpForm from '../../forms/sign-up/SignUpForm';

const srcLogoAlgaeCal = `${process.env.STATIC_PATH}${LogoAlgaeCal}`;
const srcLogoBurkeDecor = `${process.env.STATIC_PATH}${LogoBurkeDecor}`;
const srcLogoCoinRule = `${process.env.STATIC_PATH}${LogoCoinRule}`;
const srcLogoTMGM = `${process.env.STATIC_PATH}${LogoTMGM}`;
const srcMetricsUI = `${process.env.STATIC_PATH}${MetricsUI}`;

const SignUpPageExperiment2 = () => {
    const { businessUnit } = useContext(ThemeWrapperContext);

    const getHeader = () => {
        return (
            <div className='signup-exp2-header'>
                <a href={MARKETING_WEBSITE_URLS[businessUnit]}>
                    <Logo
                        i18n={i18n}
                        businessUnit={businessUnit}
                        height='30px'
                    />
                </a>
            </div>
        );
    };

    const getTitle = () => {
        return (
            <h1>
                {i18n.gettext('Smarter ads. Stronger results.')}
                <br />
                {i18n.gettext('All powered by AI you can trust.')}
            </h1>
        );
    };

    return (
        <section
            ref={(el) => {
                if (el) {
                    el.parentElement!.style.alignItems = 'stretch';
                }
            }}
            className='entryhall-page-signup-exp2'
        >
            <link
                href='https://fonts.googleapis.com/css2?family=Work+Sans:wght@700&display=swap'
                rel='stylesheet'
            ></link>
            <div className='signup-exp-2-content-left'>
                {getHeader()}
                <div className='signup-exp2-chart'>
                    <img src={srcMetricsUI} alt='Website metrics' />
                </div>
                <div className='signup-exp2-description'>
                    {getTitle()}
                    <p>
                        {i18n.gettext(
                            'Personalized, privacy-first advertising solutions designed to grow your business and simplifying your marketing.'
                        )}
                    </p>
                </div>
                <div className='signup-exp2-brands'>
                    <p>{i18n.gettext('Trusted by 140,000+ brands')}</p>
                    <ul>
                        <li>
                            <img src={srcLogoAlgaeCal} alt='AlgaeCal' />
                        </li>
                        <li>
                            <img src={srcLogoBurkeDecor} alt='Burke Decor' />
                        </li>
                        <li>
                            <img src={srcLogoCoinRule} alt='Coin Rule' />
                        </li>
                        <li>
                            <img src={srcLogoTMGM} alt='TMGM' />
                        </li>
                    </ul>
                </div>
            </div>
            <div className='signup-exp-2-content-right'>
                {getHeader()}
                {getTitle()}
                <SignUpForm />
                <Footer />
            </div>
        </section>
    );
};

export default SignUpPageExperiment2;
