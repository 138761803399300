import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

interface IProps {
    children: React.ReactNode;
}

const ReCaptchaProvider = ({ children }: IProps) => {
    useEffect(() => {
        window.reCaptchaProviderEnabled = true; // Set global variable to true when the component mounts.
        return () => {
            window.reCaptchaProviderEnabled = false; // Set global variable to false when the component unmounts.
        };
    }, []);

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.RECAPTCHA_KEY}
            useRecaptchaNet={true}
            useEnterprise={false}
            // scriptProps={{
            //     async: false,
            //     defer: false,
            //     appendTo: 'head',
            //     nonce: undefined,
            // }}
        >
            {children}
        </GoogleReCaptchaProvider>
    );
};

ReCaptchaProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ReCaptchaProvider;
