import { createContext } from 'react';

import {
    BUSINESS_UNIT_NAMES,
    BU_ADROLL,
    BU_ROLLWORKS,
    SUPPORT_EMAIL_ADDRESS,
    SupportedBUs,
} from '../constants';

interface IThemeWrapperContextValue {
    product: string;
    businessUnit: SupportedBUs;
    buName: string;
    supportEmail: string;
}

const defaultValue: IThemeWrapperContextValue = {
    product: '',
    businessUnit: BU_ADROLL,
    buName: '',
    supportEmail: '',
};

const ThemeWrapperContext =
    createContext<IThemeWrapperContextValue>(defaultValue);

export default ThemeWrapperContext;

export const buildThemeWrapperContext = (
    bu: SupportedBUs
): IThemeWrapperContextValue => ({
    product: bu === BU_ROLLWORKS ? 'b2b' : '',
    businessUnit: bu,
    buName: BUSINESS_UNIT_NAMES[bu],
    supportEmail: SUPPORT_EMAIL_ADDRESS[bu],
});
