import { useContext } from 'react';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import { InputGroup } from '@nextroll/ar-style-base';

import {
    setFieldFactory,
    setShowFieldErrorFactory,
} from '../../../actions/sign-up/SignUpActions';
import { BU_ROLLWORKS } from '../../../constants';
import ThemeWrapperContext from '../../../contexts/ThemeWrapperContext';
import SignUpContext from '../../../contexts/sign-up/SignUpContext';

export const validate = (value: string): null => {
    // Phone can be empty, so we don't need to validate it
    return null;
};

const CompanyPhone = () => {
    const { state, dispatch } = useContext(SignUpContext);
    const { businessUnit } = useContext(ThemeWrapperContext);

    const field = state.get('fields').companyPhone;
    const setField = setFieldFactory(dispatch, 'companyPhone');
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();
        const err = validate(value);
        setField(value, err === null, err);
    };
    const setShowFieldError = setShowFieldErrorFactory(
        dispatch,
        'companyPhone'
    );

    if (businessUnit !== BU_ROLLWORKS) {
        return null;
    }

    return (
        <div className='entryhall-input-field-wrapper has-feedback has-feedback-right'>
            <InputGroup
                i18n={i18n}
                classNames='entryhall-input-company-phone'
                inputProps={{
                    autoComplete: 'tel',
                    name: 'company_phone',
                    placeholder: i18n.gettext('Your Phone Number'),
                    type: 'tel',
                    value: field.value,
                    onChange,
                    onFocus: () => setShowFieldError(false),
                    onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                        onChange(e); // This is to show the error message when the user does focus out w/ having made any changes
                        setShowFieldError(true);
                    },
                }}
                validationState={
                    !field.valid && field.showError
                        ? InputGroup.VALIDATION_ERROR
                        : undefined
                }
                help={field.showError ? field.error : undefined}
            />
            <i className='form-control-feedback fa fa-phone' />
        </div>
    );
};

export default CompanyPhone;
