import { GoogleOAuthProvider } from '@react-oauth/google';
import PropTypes from 'prop-types';

// react-oauth does not export GoogleOAuthProviderProps, so we have to define it ourselves.
interface IGoogleIdentityProviderProps {
    clientId: string;
    children: React.ReactNode;
}

interface IProps extends Partial<IGoogleIdentityProviderProps> {
    children: React.ReactNode;
}

export const StubGoogleOAuthProvider = ({
    clientId,
    children,
}: IGoogleIdentityProviderProps) => <div>{children}</div>;

const GoogleIdentityProvider = ({ children }: IProps) => {
    return (
        <GoogleOAuthProvider clientId={process.env.GOOGLE_OAUTH2_CLIENT_ID}>
            {children}
        </GoogleOAuthProvider>
    );
};

GoogleIdentityProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default GoogleIdentityProvider;
