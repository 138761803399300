import i18n from '@nextroll/ar-i18n';

import { ERROR_MESSAGES } from '../../errors';
import { IField, validationFn } from '../../typings';
import { camelToSnake } from '../common';
import { isURLValid } from '../validators';

export type Fields = {
    [k: string]: IField<string>;
};

export type ExtraFields = {
    [k: string]: string | null;
};

export const INITIAL_FIELD_STATE: IField<string> = {
    value: '',
    valid: false,
    error: null,
    showError: false,
};

// List of visible fields that can be pre-filled from the URL.
// Argument name must match the field name in the context (examples: firstName, email, companyMarket).
// Passing a password via URL is a security risk, so never include it here.
export const FIELDS_FROM_URL: {
    [key: string]: [(value: string) => boolean, string][];
} = {
    url: [[isURLValid, i18n.noop.gettext('Please enter your company URL.')]],
};

// List of extra fields that can be pre-filled from the URL.
export const EXTRA_FIELDS_FROM_URL = [
    'experiment',
    'lang',
    'is_managed',
    'package_code',
    'route_to_checklist',
    'registration_product',
    'signin_next_param',
    'uuid',
    'next',
    'cohort',
    'include_cca',
    'source',
];

// List of extra fields that can be pre-filled from cookies.
// These cookies are set by Marketing team via GTM if
// the user lands on www websites or other landing pages with
// UTM parameters. Generally speaking, they do not set these URL parameters
// on links to the signup page, so we need to read them from cookies.
// This will work only if the user landed on such pages before landing here.
export const EXTRA_FIELDS_FROM_COOKIES = {
    gclid_field: 'gclidCookie',
    utm_source: 'sourceCookie',
    utm_medium: 'mediumCookie',
    utm_campaign: 'campaignCookie',
    utm_term: 'termCookie',
    utm_content: 'contentCookie',
};

export const cleanupFields = (fields: { [key: string]: string | null }) =>
    Object.entries(fields).reduce<{ [key: string]: string }>(
        (acc, [key, value]) => {
            if (value) {
                acc[key] = value;
            }
            return acc;
        },
        {}
    );

export const prepareFields = (fields: Fields) => {
    const preparedFields: { [k: string]: string } = {};
    Object.keys(fields).forEach((key) => {
        preparedFields[camelToSnake(key)] = fields[key].value;
    });
    return preparedFields;
};

export const areFieldsValid = (fields: Fields): boolean =>
    Object.values(fields).every((field) => field.valid);

export const required =
    (target: validationFn): validationFn =>
    (value: string) => {
        if (value === '') {
            return i18n.gettext(ERROR_MESSAGES.required_field);
        }
        return target(value);
    };
