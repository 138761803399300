import { useLocation } from 'react-router-dom';

import i18n from '@nextroll/ar-i18n';

export const STARTER = 'ADROLL_STARTER';
export const GROWTH = 'AR_GROWTH_V3';

/* These should be the only valid package codes at signup
See: https://www.adroll.com/pricing
*/
export const getPackageName = (packageCode: string) => {
    switch (packageCode) {
        case STARTER:
            return 'Starter';
        case GROWTH:
            return 'Growth';
        default:
            return null;
    }
};

interface ITitleProps {
    isGoogleSignUp: boolean;
}

const Title = ({ isGoogleSignUp }: ITitleProps) => {
    const search = useLocation().search;
    const packageCode = new URLSearchParams(search).get('package_code');
    const packageName = getPackageName(packageCode);

    return (
        <div className='entryhall-signup-title'>
            <div className='entryhall-signup-title-header'>
                <h1>
                    {i18n.gettext('Sign up')}
                    {isGoogleSignUp && ` ${i18n.gettext('using Google')}`}
                </h1>
            </div>
            {packageName && (
                <h3 className='entryhall-signup-title-package'>
                    {i18n.gettext('Selected Plan: {{packageName}}', {
                        packageName,
                    })}
                </h3>
            )}
        </div>
    );
};

export default Title;
