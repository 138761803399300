import { Dispatch } from 'react';

import { API_STATUSES, RememberMyDeviceStatuses } from '../../constants';
import { ErrorId, reportApiError } from '../../errors';
import { adrollApi } from '../../services/adroll';
import { ActionMap } from '../../typings';
import {
    ISetFetchErrorPayload,
    ISetFetchInProgressPayload,
    ISetFetchSuccessPayload,
    ISetInputValuePayload,
    setFetchError,
    setFetchInProgress,
    setFetchSuccess,
    setInputValue,
} from '../common';

export enum ACTION_TYPES {
    TFA_SET_CODE = 'TFA_SET_CODE',
    TFA_SET_REMEMBER_DEVICE = 'TFA_SET_REMEMBER_DEVICE',
    TFA_FETCH_METHODS_IN_PROGRESS = 'TFA_FETCH_METHOD_IN_PROGRESS',
    TFA_FETCH_METHODS_SUCCESS = 'TFA_FETCH_METHOD_SUCCESS',
    TFA_FETCH_METHODS_ERROR = 'TFA_FETCH_METHOD_ERROR',
    TFA_SEND_EMAIL_IN_PROGRESS = 'TFA_SEND_EMAIL_IN_PROGRESS',
    TFA_SEND_EMAIL_SUCCESS = 'TFA_SEND_EMAIL_SUCCESS',
    TFA_SEND_EMAIL_ERROR = 'TFA_SEND_EMAIL_ERROR',
    TFA_SEND_SMS_IN_PROGRESS = 'TFA_SEND_SMS_IN_PROGRESS',
    TFA_SEND_SMS_SUCCESS = 'TFA_SEND_SMS_SUCCESS',
    TFA_SEND_SMS_ERROR = 'TFA_SEND_SMS_ERROR',
    TFA_SIGNOUT_IN_PROGRESS = 'TFA_SIGNOUT_IN_PROGRESS',
    TFA_SIGNOUT_SUCCESS = 'TFA_SIGNOUT_SUCCESS',
    TFA_SIGNOUT_ERROR = 'TFA_SIGNOUT_ERROR',
    TFA_SUBMIT_IN_PROGRESS = 'TFA_SUBMIT_IN_PROGRESS',
    TFA_SUBMIT_SUCCESS = 'TFA_SUBMIT_SUCCESS',
    TFA_SUBMIT_ERROR = 'TFA_SUBMIT_ERROR',
}

export interface ISetFetchMethodsSuccessPayload {
    status: API_STATUSES;
    contacts: {
        email: string;
        phone: string;
    };
    methods: string[];
    redirect: string;
    to: string;
    rememberMyDeviceStatus: keyof typeof RememberMyDeviceStatuses;
}

export interface ISetFetchMethodsErrorPayload {
    status: API_STATUSES;
}

export interface ISetSubmitSuccessPayload {
    status: API_STATUSES;
    redirect: string;
    to: string;
}

export interface IRememberDevicePayload {
    checked: boolean;
}

type TFAPayloads = {
    [ACTION_TYPES.TFA_SET_CODE]: ISetInputValuePayload;
    [ACTION_TYPES.TFA_SET_REMEMBER_DEVICE]: IRememberDevicePayload;
    [ACTION_TYPES.TFA_FETCH_METHODS_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.TFA_FETCH_METHODS_SUCCESS]: ISetFetchMethodsSuccessPayload;
    [ACTION_TYPES.TFA_FETCH_METHODS_ERROR]: ISetFetchMethodsErrorPayload;
    [ACTION_TYPES.TFA_SEND_SMS_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.TFA_SEND_SMS_SUCCESS]: ISetFetchSuccessPayload;
    [ACTION_TYPES.TFA_SEND_SMS_ERROR]: ISetFetchErrorPayload;
    [ACTION_TYPES.TFA_SEND_EMAIL_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.TFA_SEND_EMAIL_SUCCESS]: ISetFetchSuccessPayload;
    [ACTION_TYPES.TFA_SEND_EMAIL_ERROR]: ISetFetchErrorPayload;
    [ACTION_TYPES.TFA_SIGNOUT_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.TFA_SIGNOUT_SUCCESS]: ISetSubmitSuccessPayload;
    [ACTION_TYPES.TFA_SIGNOUT_ERROR]: ISetFetchErrorPayload;
    [ACTION_TYPES.TFA_SUBMIT_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.TFA_SUBMIT_SUCCESS]: ISetSubmitSuccessPayload;
    [ACTION_TYPES.TFA_SUBMIT_ERROR]: ISetFetchErrorPayload;
};

export type TFAActions = ActionMap<TFAPayloads>[keyof ActionMap<TFAPayloads>];

export const setCodeFactory =
    (
        dispatchFn: Dispatch<TFAActions>,
        alreadySubmittedOnce: boolean,
        rememberDevice: boolean
    ) =>
    (event: any) => {
        const code = event.target.value;
        dispatchFn(
            setInputValue(ACTION_TYPES.TFA_SET_CODE, code, () => [true, null])
        );
        if (code.length === 6 && !alreadySubmittedOnce && window.Cypress) {
            submitFactory(dispatchFn, code, rememberDevice)();
        }
    };

const setFetchMethodsSuccess = (response: any): TFAActions => {
    const rmdsFromApi = response.remember_my_device_status;
    const rememberMyDeviceStatus =
        rmdsFromApi in RememberMyDeviceStatuses
            ? rmdsFromApi
            : RememberMyDeviceStatuses.unknown;
    return {
        type: ACTION_TYPES.TFA_FETCH_METHODS_SUCCESS,
        payload: {
            status: API_STATUSES.SUCCESS,
            methods: response.tfa_methods,
            contacts: response.contacts,
            redirect: response.type,
            to: response.action,
            rememberMyDeviceStatus,
        },
    };
};

const setFetchMethodsError = (): TFAActions => {
    return {
        type: ACTION_TYPES.TFA_FETCH_METHODS_ERROR,
        payload: {
            status: API_STATUSES.ERROR,
        },
    };
};

const setSignOutSuccess = (response: any): TFAActions => {
    return {
        type: ACTION_TYPES.TFA_SIGNOUT_SUCCESS,
        payload: {
            status: API_STATUSES.SUCCESS,
            redirect: response.type,
            to: response.action,
        },
    };
};

const setSubmitSuccess = (response: any): TFAActions => {
    return {
        type: ACTION_TYPES.TFA_SUBMIT_SUCCESS,
        payload: {
            status: API_STATUSES.SUCCESS,
            redirect: response.type,
            to: response.action,
        },
    };
};

const setRememberDevice = (checked: boolean): TFAActions => {
    return {
        type: ACTION_TYPES.TFA_SET_REMEMBER_DEVICE,
        payload: {
            checked,
        },
    };
};

export const setRememberDeviceFactory =
    (dispatchFn: Dispatch<TFAActions>) => (event: any) => {
        const checked = event.target.checked;
        dispatchFn(setRememberDevice(checked));
    };

export const fetchMethodsFactory =
    (dispatchFn: Dispatch<TFAActions>) => async () => {
        dispatchFn(
            setFetchInProgress(ACTION_TYPES.TFA_FETCH_METHODS_IN_PROGRESS)
        );
        try {
            const res = await adrollApi.get('entryhall/init_tfa_page');
            dispatchFn(setFetchMethodsSuccess(res));
        } catch (error) {
            dispatchFn(setFetchMethodsError());
        }
    };

export const sendSMSFactory =
    (dispatchFn: Dispatch<TFAActions>) => async () => {
        dispatchFn(setFetchInProgress(ACTION_TYPES.TFA_SEND_SMS_IN_PROGRESS));
        try {
            await adrollApi.post('entryhall/send_sms_tfa', {}, {});
            dispatchFn(setFetchSuccess(ACTION_TYPES.TFA_SEND_SMS_SUCCESS));
        } catch (error) {
            dispatchFn(setFetchError(ACTION_TYPES.TFA_SEND_SMS_ERROR, error));
        }
    };

export const sendEmailFactory =
    (dispatchFn: Dispatch<TFAActions>) => async () => {
        dispatchFn(setFetchInProgress(ACTION_TYPES.TFA_SEND_EMAIL_IN_PROGRESS));
        try {
            await adrollApi.post('entryhall/send_email_tfa', {}, {});
            dispatchFn(setFetchSuccess(ACTION_TYPES.TFA_SEND_EMAIL_SUCCESS));
        } catch (error) {
            if (
                error.parsedBody?.errors?.[0]?.value ===
                ErrorId.email_rate_limited
            ) {
                dispatchFn(
                    setFetchSuccess(ACTION_TYPES.TFA_SEND_EMAIL_SUCCESS)
                );
                return;
            }
            dispatchFn(setFetchError(ACTION_TYPES.TFA_SEND_EMAIL_ERROR, error));
        }
    };

export const signOutFactory =
    (dispatchFn: Dispatch<TFAActions>) => async () => {
        dispatchFn(setFetchInProgress(ACTION_TYPES.TFA_SIGNOUT_IN_PROGRESS));
        try {
            const res = await adrollApi.post('entryhall/signout', {}, {});
            dispatchFn(setSignOutSuccess(res));
        } catch (error) {
            reportApiError(error);
            dispatchFn(setFetchError(ACTION_TYPES.TFA_SIGNOUT_ERROR, error));
        }
    };

export const submitFactory =
    (dispatchFn: Dispatch<TFAActions>, code: string, rememberDevice: boolean) =>
    async () => {
        dispatchFn(setFetchInProgress(ACTION_TYPES.TFA_SUBMIT_IN_PROGRESS));
        try {
            const res = await adrollApi.post(
                'entryhall/verify_tfa',
                {},
                {
                    token: code,
                    remember_user_device: rememberDevice,
                }
            );
            dispatchFn(setSubmitSuccess(res));
        } catch (error) {
            reportApiError(error);
            dispatchFn(setFetchError(ACTION_TYPES.TFA_SUBMIT_ERROR, error));
        }
    };
