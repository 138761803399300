import {
    CredentialResponse,
    GoogleLogin,
    GoogleLoginProps,
} from '@react-oauth/google';
import PropTypes from 'prop-types';

import { reportError } from '../errors';

// Re-exporting the CredentialResponse type to make it available to other components.
export type { CredentialResponse };

export interface IIdInfo {
    email: string;
    email_verified: boolean;
    given_name: string;
    family_name: string;
    hd?: string;
}

interface IProps extends Partial<GoogleLoginProps> {
    onSuccess: (response: CredentialResponse) => void;
    onError: () => void;
    text: 'signin_with' | 'signup_with';
    width?: number;
}

export const StubGoogleLogin = (props: GoogleLoginProps) => (
    <div {...props}>{props.text}</div>
);

const GoogleButton = ({
    onSuccess,
    onError,
    text,
    width,
    click_listener,
}: IProps) => {
    const onErrorWrapper = () => {
        reportError(new Error('GoogleButton.onError'), {});
        onError();
    };
    return (
        <GoogleLogin
            onSuccess={onSuccess}
            onError={onErrorWrapper}
            click_listener={click_listener}
            type='standard'
            theme='outline'
            size='large'
            text={text}
            shape='rectangular'
            logo_alignment='center'
            width={width}
            ux_mode='popup'
            use_fedcm_for_prompt={true}
        />
    );
};

GoogleButton.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    text: PropTypes.oneOf(['signin_with', 'signup_with']).isRequired,
    width: PropTypes.number,
};

export default GoogleButton;
