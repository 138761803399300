import { useContext, useState } from 'react';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-password-input
import PasswordInput from '@nextroll/ar-password-input';

import {
    setPasswordConfirmationFactory,
    setPasswordFactory,
    submitFactory,
} from '../../actions/password-reset/PasswordResetSaveActions';
import InlineFeedback from '../../components/InlineFeedback';
import { ApiError } from '../../components/errors';
import { API_STATUSES } from '../../constants';
import PasswordResetSaveContext from '../../contexts/password-reset/PasswordResetSaveContext';
import { ERROR_MESSAGES } from '../../errors';

const PasswordResetSaveForm = () => {
    const [showPasswordValidationError, setShowPasswordValidationError] =
        useState(false);
    const [
        showConfirmationValidationError,
        setShowConfirmationValidationError,
    ] = useState(false);
    const { state, dispatch } = useContext(PasswordResetSaveContext);
    const { email, token } = state.get('urlParams');
    const password = state.get('password');
    const passwordConfirmation = state.get('passwordConfirmation');
    const {
        status: submitStatus,
        error: submitError,
        showError: showSubmitError,
    } = state.get('submit');

    const setPassword = setPasswordFactory(dispatch);
    const setPasswordConfirmation = setPasswordConfirmationFactory(dispatch);
    const submit = submitFactory(
        dispatch,
        email,
        token,
        password.value,
        passwordConfirmation.value
    );

    const arePasswordMatching = password.value === passwordConfirmation.value;
    const buttonDisabled =
        !password.valid || !passwordConfirmation.valid || !arePasswordMatching;
    // show the password not matching error only when the user is typing the password confirmation
    // and both the inputs are already valid. This is a developer decision.
    const showPasswordsNotMatchingError =
        !arePasswordMatching &&
        showPasswordValidationError &&
        password.valid &&
        passwordConfirmation.valid;

    return (
        <div className='entryhall-card-block entryhall-form-password-reset-save'>
            <div className='entryhall-card-header'>
                <p className='entryhall-card-header-title'>
                    {i18n.gettext('Create New Password')}
                </p>

                {!showSubmitError && !showPasswordsNotMatchingError && (
                    <p>
                        {i18n.gettext(
                            'Your new password must be different from your previous passwords'
                        )}
                    </p>
                )}
                {showPasswordsNotMatchingError && (
                    <p className='entryhall-error-text'>
                        {i18n.gettext(ERROR_MESSAGES.confirmation_not_match)}
                    </p>
                )}
                {showSubmitError && <ApiError error={submitError} />}
            </div>
            <div className='entryhall-card-body'>
                <PasswordInput
                    i18n={i18n}
                    label={i18n.gettext('New Password')}
                    inputProps={{
                        autoFocus: true,
                        autoComplete: 'new-password',
                        value: password.value,
                        onChange: setPassword,
                        onBlur: () => setShowPasswordValidationError(true),
                        // onFocus: () =>
                        //     showPasswordValidationError &&
                        //     setShowPasswordValidationError(false),
                    }}
                    errors={password.error}
                    showErrors={showPasswordValidationError}
                    enableValidation={true}
                />

                <PasswordInput
                    i18n={i18n}
                    label={i18n.gettext('Confirm Password')}
                    inputProps={{
                        autoComplete: 'new-password',
                        value: passwordConfirmation.value,
                        onChange: setPasswordConfirmation,
                        onBlur: () => setShowConfirmationValidationError(true),
                        // onFocus: () =>
                        //     showConfirmationValidationError &&
                        //     setShowConfirmationValidationError(false),
                    }}
                    errors={passwordConfirmation.error}
                    showErrors={showConfirmationValidationError}
                    enableValidation={true}
                />
            </div>
            <div className='entryhall-card-footer'>
                <button
                    type='button'
                    className='btn btn-primary'
                    disabled={buttonDisabled}
                    onClick={
                        submitStatus !== API_STATUSES.IN_PROGRESS
                            ? submit
                            : undefined
                    }
                >
                    {i18n.gettext('Change Password')}
                    <InlineFeedback status={submitStatus} />
                </button>
            </div>
        </div>
    );
};

export default PasswordResetSaveForm;
