import classNames from 'classnames';
import { useContext } from 'react';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import { Option, Select } from '@nextroll/ar-style-base';

import {
    setFieldFactory,
    setShowFieldErrorFactory,
} from '../../../actions/sign-up/SignUpActions';
import SignUpContext from '../../../contexts/sign-up/SignUpContext';
import { required } from '../../../utils/sign-up/fields';

export const getStateCodes = (
    countryCode: string
): { [key: string]: string } => {
    if (countryCode === 'US') {
        return i18n.STATE_CODES as { [key: string]: string };
    }
    if (countryCode === 'CA') {
        return i18n.PROVINCE_CODES as { [key: string]: string };
    }
    return {};
};

// These are not a constant because of i18n and country code.
export const getOptions = (
    countryCode: string
): { code: string; displayName: string }[] => {
    const stateCodes = getStateCodes(countryCode);
    const stateList = Object.entries(stateCodes).map(([key, value]) => ({
        code: key,
        displayName: i18n.gettext(value as string),
    }));

    stateList.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));

    return stateList;
};

export const validate = required((value: string) => null);

export const searchCallbackFactory =
    (countryCode: string) => (search: string, optionValue: string) =>
        getStateCodes(countryCode)
            [optionValue].toLowerCase()
            .includes(search.toLowerCase());

const Region = () => {
    const { state, dispatch } = useContext(SignUpContext);

    const countryCode = state.get('fields').countryCode.value;
    const field = state.get('fields').region;
    const setField = setFieldFactory(dispatch, 'region');
    const onChange = (value: string) => {
        const err = validate(value);
        setField(value, err === null, err);
    };
    const setShowFieldError = setShowFieldErrorFactory(dispatch, 'region');

    if (countryCode !== 'US' && countryCode !== 'CA') {
        return null;
    }
    // This is calculated on-the-fly because we cannot trigger an onChange from the onClose (which is not
    // feasible because we don't have access to the new value yet)
    const error = validate(field.value);

    return (
        <div
            className={classNames('form-group entryhall-select', {
                'has-error': field.showError && error,
                'no-selected': field.value === '',
            })}
        >
            <div className='entryhall-input-field-wrapper has-feedback has-feedback-right'>
                <Select
                    i18n={i18n}
                    className='entryhall-input-region'
                    placeholder={i18n.gettext('Company State')}
                    showSearch={true}
                    searchCallback={searchCallbackFactory(countryCode)}
                    onOptionSelect={onChange}
                    onOpen={() => setShowFieldError(false)}
                    onClose={() => setShowFieldError(true)}
                >
                    {getOptions(countryCode).map((opt) => (
                        <Option
                            key={opt.code}
                            value={opt.code}
                            selected={opt.code === field.value}
                        >
                            {i18n.gettext(opt.displayName)}
                        </Option>
                    ))}
                </Select>
                <i className='form-control-feedback fa fa-map-marker-alt' />
            </div>
            {field.showError && error && (
                <span className='help-block ar-input-group-help'>{error}</span>
            )}
        </div>
    );
};

export default Region;
