import Cookies from 'js-cookie';
import { Dispatch } from 'react';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for telemetry
import telemetry from '@nextroll/telemetry';

import { API_STATUSES, SupportedBUs } from '../../constants';
import { reportApiError } from '../../errors';
import { adrollApi } from '../../services/adroll';
import { ActionMap, IField } from '../../typings';
import {
    EXTRA_FIELDS_FROM_COOKIES,
    EXTRA_FIELDS_FROM_URL,
    ExtraFields,
    FIELDS_FROM_URL,
    Fields,
    cleanupFields,
    prepareFields,
} from '../../utils/sign-up/fields';
import { sendMarketoToGAEvent } from '../../utils/sign-up/marketing';
import {
    ISetFetchErrorPayload,
    ISetFetchInProgressPayload,
    setFetchError,
    setFetchInProgress,
} from '../common';

// Definitions

export enum ACTION_TYPES {
    SIGN_UP_UPDATE_FIELD = 'SIGN_UP_UPDATE_FIELD',
    SIGN_UP_UPDATE_EXTRA_FIELDS = 'SIGN_UP_UPDATE_EXTRA_FIELDS',
    SIGN_UP_SET_RECAPTCHA_TOKEN = 'SIGN_UP_SET_RECAPTCHA_TOKEN',
    SIGN_UP_SET_GOOGLE_ID_TOKEN = 'SIGN_UP_SET_GOOGLE_ID_TOKEN',
    SIGN_UP_SUBMIT_IN_PROGRESS = 'SIGN_UP_SUBMIT_IN_PROGRESS',
    SIGN_UP_SUBMIT_SUCCESS = 'SIGN_UP_SUBMIT_SUCCESS',
    SIGN_UP_SUBMIT_ERROR = 'SIGN_UP_SUBMIT_ERROR',
}

export interface ISetFetchSuccessPayload {
    status: API_STATUSES;
    redirect: string;
    to: string;
}

export interface ISetFieldPayload {
    fieldName: string;
    data: Partial<IField<string>>;
}

export interface ISetExtraFieldsPayload {
    data: ExtraFields;
}

export interface ISetReCaptchaTokenPayload {
    token: string;
    expiresAt: Date;
}

export interface ISetGoogleIdTokenPayload {
    token: string;
}

type SignUpPayloads = {
    [ACTION_TYPES.SIGN_UP_UPDATE_FIELD]: ISetFieldPayload;
    [ACTION_TYPES.SIGN_UP_UPDATE_EXTRA_FIELDS]: ISetExtraFieldsPayload;
    [ACTION_TYPES.SIGN_UP_SET_RECAPTCHA_TOKEN]: ISetReCaptchaTokenPayload;
    [ACTION_TYPES.SIGN_UP_SET_GOOGLE_ID_TOKEN]: ISetGoogleIdTokenPayload;
    [ACTION_TYPES.SIGN_UP_SUBMIT_IN_PROGRESS]: ISetFetchInProgressPayload;
    [ACTION_TYPES.SIGN_UP_SUBMIT_SUCCESS]: ISetFetchSuccessPayload;
    [ACTION_TYPES.SIGN_UP_SUBMIT_ERROR]: ISetFetchErrorPayload;
};

export type SignUpActions =
    ActionMap<SignUpPayloads>[keyof ActionMap<SignUpPayloads>];

// Factories

export const setFieldFactory =
    (dispatchFn: Dispatch<SignUpActions>, fieldName: string) =>
    (value: string, valid: boolean, error: any) => {
        dispatchFn({
            type: ACTION_TYPES.SIGN_UP_UPDATE_FIELD,
            payload: {
                fieldName,
                data: {
                    value,
                    valid,
                    error,
                },
            },
        });
    };

export const setShowFieldErrorFactory =
    (dispatchFn: Dispatch<SignUpActions>, fieldName: string) =>
    (showError: boolean) => {
        dispatchFn({
            type: ACTION_TYPES.SIGN_UP_UPDATE_FIELD,
            payload: {
                fieldName,
                data: {
                    showError,
                },
            },
        });
    };

export const setReCaptchaFactory =
    (
        dispatchFn: Dispatch<SignUpActions>,
        executeRecaptcha: (action?: string) => Promise<string>
    ) =>
    async (): Promise<string> => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        let token = '';
        try {
            token = await executeRecaptcha('registration');
        } catch (error) {
            console.log(`Unable to fetch reCaptcha token ${error}`);
            return;
        }

        const expiresAt = new Date();
        expiresAt.setMinutes(expiresAt.getMinutes() + 2);

        dispatchFn({
            type: ACTION_TYPES.SIGN_UP_SET_RECAPTCHA_TOKEN,
            payload: {
                token,
                expiresAt,
            },
        });

        return token;
    };

export const setGoogleIdTokenFactory =
    (dispatchFn: Dispatch<SignUpActions>) => (token: string) => {
        dispatchFn({
            type: ACTION_TYPES.SIGN_UP_SET_GOOGLE_ID_TOKEN,
            payload: {
                token,
            },
        });
    };

export const submitFactory =
    (
        dispatchFn: Dispatch<SignUpActions>,
        fields: Fields,
        businessUnit: SupportedBUs,
        googleIdToken: string,
        extraFields: ExtraFields,
        {
            token,
            expiresAt,
            executeReCaptcha,
        }: {
            token: string;
            expiresAt: Date;
            executeReCaptcha: () => Promise<string>;
        }
    ) =>
    async () => {
        dispatchFn(setFetchInProgress(ACTION_TYPES.SIGN_UP_SUBMIT_IN_PROGRESS));

        if (!token || expiresAt < new Date()) {
            try {
                token = await executeReCaptcha();
            } catch (error) {
                console.log('Unable to fetch reCaptcha token');
            }
        }

        try {
            const res = await adrollApi.post(
                'entryhall/onboarding_register',
                {},
                {
                    ...cleanupFields(extraFields),
                    ...prepareFields(fields),
                    business_unit: businessUnit,
                    g_token: googleIdToken,
                    recaptcha_token: token,
                    recaptcha_site_key: process.env.RECAPTCHA_KEY,
                }
            );

            telemetry.track('entryhall-signup-form-success');
            await sendMarketoToGAEvent(fields, extraFields);

            dispatchFn({
                type: ACTION_TYPES.SIGN_UP_SUBMIT_SUCCESS,
                payload: {
                    status: API_STATUSES.SUCCESS,
                    redirect: res.type,
                    to: res.action,
                },
            });
        } catch (error) {
            reportApiError(error);
            dispatchFn(setFetchError(ACTION_TYPES.SIGN_UP_SUBMIT_ERROR, error));
        }
    };

export const initFormState = (
    dispatchFn: Dispatch<SignUpActions>,
    params: URLSearchParams
) => {
    const extraFields: ExtraFields = {
        ...EXTRA_FIELDS_FROM_URL.reduce(
            (acc, key) => ({
                ...acc,
                [key]: params.get(key),
            }),
            {}
        ),
        ...Object.entries(EXTRA_FIELDS_FROM_COOKIES).reduce(
            (acc, [key, value]) => ({
                ...acc,
                [key]: Cookies.get(value) || null,
            }),
            {}
        ),
        referrer: document.referrer || 'Direct',
        signin_next_param: params.get('next')
            ? encodeURIComponent(params.get('next'))
            : null,
    };

    dispatchFn({
        type: ACTION_TYPES.SIGN_UP_UPDATE_EXTRA_FIELDS,
        payload: {
            data: extraFields,
        },
    });

    Object.entries(FIELDS_FROM_URL).forEach(([key, validators]) => {
        const value = params.get(key);
        if (value) {
            const error = Object.values(validators).reduce(
                (acc, validator) =>
                    acc ||
                    (validator[0](value) ? null : i18n.gettext(validator[1])),
                null
            );

            dispatchFn({
                type: ACTION_TYPES.SIGN_UP_UPDATE_FIELD,
                payload: {
                    fieldName: key,
                    data: {
                        value,
                        valid: error === null,
                        error,
                        showError: true,
                    },
                },
            });
        }
    });
};
