import classNames from 'classnames';
import { useContext, useEffect } from 'react';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import {
    Option,
    Select, // @ts-ignore - no types for ar-style-base
} from '@nextroll/ar-style-base';

import {
    setFieldFactory,
    setShowFieldErrorFactory,
} from '../../../actions/sign-up/SignUpActions';
import { BU_ROLLWORKS } from '../../../constants';
import ThemeWrapperContext from '../../../contexts/ThemeWrapperContext';
import SignUpContext from '../../../contexts/sign-up/SignUpContext';
import { required } from '../../../utils/sign-up/fields';

export const COMPANY_MARKET_OPTIONS = [
    {
        code: 'b2c',
        displayName: i18n.noop.gettext('Business to Consumer (B2C)'),
    },
    {
        code: 'b2b',
        displayName: i18n.noop.gettext('Business to Business (B2B)'),
    },
    { code: 'b2bb2c', displayName: i18n.noop.gettext('Both B2B and B2C') },
    { code: 'agency', displayName: i18n.noop.gettext('B2C Agency (AdRoll)') },
    {
        code: 'b2bagency',
        displayName: i18n.noop.gettext('B2B Agency (RollWorks)'),
    },
    { code: 'other', displayName: i18n.noop.gettext('Other') },
];

export const validate = required((value: string) => null);

const CompanyMarket = () => {
    const { state, dispatch } = useContext(SignUpContext);
    const { businessUnit } = useContext(ThemeWrapperContext);

    const field = state.get('fields').companyMarket;
    const setField = setFieldFactory(dispatch, 'companyMarket');
    const onChange = (value: string) => {
        const err = validate(value);
        setField(value, err === null, err);
    };
    const setShowFieldError = setShowFieldErrorFactory(
        dispatch,
        'companyMarket'
    );
    // This is calculated on-the-fly because we cannot trigger an onChange from the onClose (which is not
    // feasible because we don't have access to the new value yet)
    const error = validate(field.value);

    const isRollWorks = businessUnit === BU_ROLLWORKS;
    useEffect(() => {
        if (isRollWorks) {
            setField('b2b', true, null);
        }
    }, [isRollWorks]);

    if (isRollWorks) {
        return null;
    }

    return (
        <div
            className={classNames('form-group entryhall-select', {
                'has-error': field.showError && error,
                'no-selected': field.value === '',
            })}
        >
            <div className='entryhall-input-field-wrapper has-feedback has-feedback-right'>
                <Select
                    i18n={i18n}
                    className='entryhall-input-company-market'
                    placeholder={i18n.gettext(
                        'Which best describes your business?'
                    )}
                    onOptionSelect={onChange}
                    onOpen={() => setShowFieldError(false)}
                    onClose={() => setShowFieldError(true)}
                >
                    {COMPANY_MARKET_OPTIONS.map((opt) => (
                        <Option
                            key={opt.code}
                            value={opt.code}
                            selected={opt.code === field.value}
                        >
                            {i18n.gettext(opt.displayName)}
                        </Option>
                    ))}
                </Select>
                <i className='form-control-feedback fa fa-building' />
            </div>
            {field.showError && error && (
                <span className='help-block ar-input-group-help'>{error}</span>
            )}
        </div>
    );
};

export default CompanyMarket;
