import { useContext } from 'react';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-style-base
import { InputGroup } from '@nextroll/ar-style-base';

import {
    setFieldFactory,
    setShowFieldErrorFactory,
} from '../../../actions/sign-up/SignUpActions';
// @ts-ignore - no types for an image.
import GoogleIcon from '../../../assets/google.svg';
import { EmailValidationError } from '../../../components/errors';
import SignUpContext from '../../../contexts/sign-up/SignUpContext';
import { required } from '../../../utils/sign-up/fields';
import { isEmailValid } from '../../../utils/validators';

const srcGoogleIcon = `${process.env.STATIC_PATH}${GoogleIcon}`;

export const validate = required((value: string) => {
    if (!isEmailValid(value)) {
        return <EmailValidationError />;
    }
    return null;
});

const Email = () => {
    const { state, dispatch } = useContext(SignUpContext);

    const field = state.get('fields').email;
    const isGSignUp = state.get('googleIdToken') !== '';
    const setField = setFieldFactory(dispatch, 'email');
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const err = validate(value);
        setField(value, err === null, err);
    };
    const setShowFieldError = setShowFieldErrorFactory(dispatch, 'email');

    return (
        <div className='entryhall-input-field-wrapper has-feedback has-feedback-right'>
            <InputGroup
                i18n={i18n}
                classNames='entryhall-input-email'
                inputProps={{
                    autoComplete: 'email',
                    name: 'email',
                    placeholder: i18n.gettext('Your Email'),
                    type: 'email',
                    value: field.value,
                    disabled: isGSignUp,
                    onChange,
                    onFocus: () => setShowFieldError(false),
                    onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                        onChange(e); // This is to show the error message when the user does focus out w/ having made any changes
                        setShowFieldError(true);
                    },
                }}
                validationState={
                    !field.valid && field.showError
                        ? InputGroup.VALIDATION_ERROR
                        : undefined
                }
                help={field.showError ? field.error : undefined}
            />
            {isGSignUp ? (
                <img
                    className='form-control-feedback google-icon'
                    src={srcGoogleIcon}
                />
            ) : (
                <i className='form-control-feedback fa fa-envelope' />
            )}
        </div>
    );
};

export default Email;
