import { useContext, useState } from 'react';

import i18n from '@nextroll/ar-i18n';
// @ts-ignore - no types for ar-password-input
import PasswordInput from '@nextroll/ar-password-input';
// @ts-ignore - no types for ar-style-base
import { InputGroup } from '@nextroll/ar-style-base';

import {
    setCurrentPasswordFactory,
    setPasswordConfirmationFactory,
    setPasswordFactory,
    submitFactory,
} from '../../actions/password-expired/PasswordExpiredActions';
import InlineFeedback from '../../components/InlineFeedback';
import { ApiError } from '../../components/errors';
import { API_STATUSES } from '../../constants';
import PasswordExpiredContext from '../../contexts/password-expired/PasswordExpiredContext';
import { ERROR_MESSAGES } from '../../errors';

const PasswordExpiredForm = () => {
    const [
        showCurrentPasswordValidationError,
        setShowCurrentPasswordValidationError,
    ] = useState(false);
    const [showPasswordValidationError, setShowPasswordValidationError] =
        useState(false);
    const [
        showConfirmationValidationError,
        setShowConfirmationValidationError,
    ] = useState(false);
    const { state, dispatch } = useContext(PasswordExpiredContext);

    const currentPassword = state.get('currentPassword');
    const password = state.get('password');
    const passwordConfirmation = state.get('passwordConfirmation');
    const {
        status: submitStatus,
        error: submitError,
        showError: showSubmitError,
    } = state.get('submit');

    const setCurrentPassword = setCurrentPasswordFactory(dispatch);
    const setPassword = setPasswordFactory(dispatch);
    const setPasswordConfirmation = setPasswordConfirmationFactory(dispatch);
    const submit = submitFactory(
        dispatch,
        currentPassword.value,
        password.value,
        passwordConfirmation.value
    );

    const arePasswordMatching = password.value === passwordConfirmation.value;
    const buttonDisabled =
        !currentPassword.valid ||
        !password.valid ||
        !passwordConfirmation.valid ||
        !arePasswordMatching;
    // show the password not matching error only when the user is typing the password confirmation
    // and both the inputs are already valid. This is a developer decision.
    const showPasswordsNotMatchingError =
        !arePasswordMatching &&
        showPasswordValidationError &&
        password.valid &&
        passwordConfirmation.valid;

    return (
        <div className='entryhall-card-block entryhall-form-password-expired'>
            <div className='entryhall-card-header'>
                <p className='entryhall-card-header-title'>
                    {i18n.gettext('Your password is expired')}
                </p>

                {!showSubmitError && !showPasswordsNotMatchingError && (
                    <>
                        <p>
                            {i18n.gettext(
                                'Your account settings require you to change your password.'
                            )}
                        </p>
                        <p>{i18n.gettext('Please create a new password.')}</p>
                    </>
                )}
                {showPasswordsNotMatchingError && (
                    <p className='entryhall-error-text'>
                        {i18n.gettext(ERROR_MESSAGES.confirmation_not_match)}
                    </p>
                )}
                {showSubmitError && <ApiError error={submitError} />}
            </div>
            <div className='entryhall-card-body'>
                <InputGroup
                    i18n={i18n}
                    label={i18n.gettext('Current Password')}
                    inputProps={{
                        type: 'password',
                        name: 'entryhall-password-expired-current-password',
                        id: 'entryhall-password-expired-current-password',
                        autoComplete: 'current-password',
                        value: currentPassword.value,
                        onChange: setCurrentPassword,
                        onBlur: () =>
                            setShowCurrentPasswordValidationError(true),
                    }}
                    validationState={
                        showCurrentPasswordValidationError &&
                        !currentPassword.valid
                            ? InputGroup.VALIDATION_ERROR
                            : ''
                    }
                />

                <PasswordInput
                    i18n={i18n}
                    label={i18n.gettext('New Password')}
                    inputProps={{
                        autoComplete: 'new-password',
                        value: password.value,
                        onChange: setPassword,
                        onBlur: () => setShowPasswordValidationError(true),
                    }}
                    errors={password.error}
                    showErrors={showPasswordValidationError}
                    enableValidation={true}
                />

                <PasswordInput
                    i18n={i18n}
                    label={i18n.gettext('Confirm Password')}
                    inputProps={{
                        autoComplete: 'new-password',
                        value: passwordConfirmation.value,
                        onChange: setPasswordConfirmation,
                        onBlur: () => setShowConfirmationValidationError(true),
                    }}
                    errors={passwordConfirmation.error}
                    showErrors={showConfirmationValidationError}
                    enableValidation={true}
                />
            </div>
            <div className='entryhall-card-footer'>
                <button
                    type='button'
                    className='btn btn-primary'
                    disabled={buttonDisabled}
                    onClick={
                        submitStatus !== API_STATUSES.IN_PROGRESS
                            ? submit
                            : undefined
                    }
                >
                    {i18n.gettext('Change Password')}
                    <InlineFeedback status={submitStatus} />
                </button>
            </div>
        </div>
    );
};

export default PasswordExpiredForm;
