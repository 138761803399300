import PropTypes from 'prop-types';

import {
    ThemeLoader, // @ts-ignore - no types for ar-style-base
} from '@nextroll/ar-style-base';

import { BU_ADROLL, BU_ROLLWORKS, SupportedBUs } from '../constants';
import ThemeWrapperContext, {
    buildThemeWrapperContext,
} from '../contexts/ThemeWrapperContext';

interface IProps {
    children: React.ReactChild;
}

// ThemeLoader-related constants.
const CSS_FILES = {
    [BU_ADROLL]: 'entryhall-style-adroll.css',
    [BU_ROLLWORKS]: 'entryhall-style-rollworks.css',
};
const STATIC_PATH = process.env.STATIC_PATH;

const ThemeWrapper = ({ children }: IProps) => {
    const product = new URLSearchParams(window.location.search).get('product');
    const businessUnit: SupportedBUs =
        product === 'b2b' ? BU_ROLLWORKS : BU_ADROLL;

    return (
        <ThemeWrapperContext.Provider
            value={buildThemeWrapperContext(businessUnit)}
        >
            <ThemeLoader
                advertisable={{
                    business_unit: businessUnit,
                    is_b2b: product === 'b2b',
                }}
                cssFiles={CSS_FILES}
                staticPath={STATIC_PATH}
            >
                {children}
            </ThemeLoader>
        </ThemeWrapperContext.Provider>
    );
};

ThemeWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ThemeWrapper;
