// This string validation is a combination of three regex patterns:
// 1. Non-empty string: /.*\S+.*/
// 2. No forward slashes: /^[^\/]*$/
// 3. No angle brackets: /^[^<>]+$/
// https://github.com/SemanticSugar/let-me-in/blob/main/src/constants/validation.js (we extended let-me-in validation rules)
const TEXTUAL_STRING_VALIDATION_PATTERNS = [/.*\S+.*/, /^[^\/]*$/, /^[^<>]+$/];
const EMAIL_VALIDATION_PATTERN =
    /^[a-zA-Z0-9_+&-]+(?:\.[a-zA-Z0-9_+&-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/;
// https://github.com/SemanticSugar/let-me-in/blob/main/src/constants/validation.js
const URL_VALIDATION_PATTERN =
    '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$';

const EmailValidationRegex = RegExp(EMAIL_VALIDATION_PATTERN);
const URLValidationRegex = RegExp(URL_VALIDATION_PATTERN);

export const isEmailValid = (email: string): boolean =>
    EmailValidationRegex.test(email);

export const isURLValid = (url: string): boolean =>
    URLValidationRegex.test(url);

export const isValidTextualString = (text: string): boolean =>
    TEXTUAL_STRING_VALIDATION_PATTERNS.every((pattern) => pattern.test(text));
